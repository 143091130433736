<template>
  <div class="home">
    <div class="row justify-content-center mt-5 mx-0 px-0">
      <div class="col-md-10 m-0">
        <div class="card-home">
          <h1 class="goldx-title">The GoldXchain is Live.</h1>
          <div class="my-3">
            <img src="@/assets/images/goldXchain-sm.png" class="d-inline-block mwc" alt="">
          <p class="d-inline-block p-black">Mainnet & Faucet</p>
          </div>
          <p class="p-gold mb-0"> <a href="https://explorer.goldxchain.io" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://explorer.goldxchain.io </a></p>   
          <p class="p-gold mb-0"> <a href="https://goldxscan.com/" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://goldxscan.com (Backup)</a></p>   
          <p class="p-gold mb-0"> <a href="https://mining.goldxchain.io" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://mining.goldxchain.io </a> </p>
          <p class="p-gold mb-0"> <a href="https://status.goldxchain.io" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://status.goldxchain.io </a> </p>
          <p class="p-gold mb-0"> <a href="https://goldx.gold" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://goldx.gold </a> </p>
          <p class="p-gold mb-0"> <a href="https://fortune.gold" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://fortune.gold </a> </p>
          <p class="p-gold mb-0"> <a href="https://nft.fortune.gold" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://nft.fortune.gold </a> </p>
          <!-- <p class="p-gold mb-0"> <a href="https://goldx.gold" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://goldx.gold </a> </p> -->

          <div class="my-3">
            <img src="@/assets/images/goldXchain-sm.png" class="d-inline-block mwc" alt="">
          <p class="d-inline-block p-black">Testnet & Faucet</p>
          </div>
          
          <p class="p-gold mb-0"> <a href="https://testnet-explorer.goldxchain.io" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://testnet-explorer.goldxchain.io </a> </p>
          <p class="p-gold mb-0"><a href="https://faucet.goldxchain.io" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://faucet.goldxchain.io </a>  </p>

          <img src="@/assets/images/goldXexchange.png" class="d-block mwc my-3" alt="">

          <p class="p-gold mb-0"> <a href="https://goldx.finance" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://goldx.finance </a> </p>
          <p class="p-gold mb-0"> <a href="https://fuse.gold" target="_blank" rel="noopener noreferrer" style="color:inherit;"> https://fuse.gold </a> </p>
           
        </div>
      </div>
      <div class="col-md-10 mx-0 my-4">
        <div class="card-home">
          <h1 class="goldx-title">What is the GoldXchain?</h1>
          <div class="my-3">
          <p class="d-inline-block p-black-para">
            The goldXchain is a fork of Ethereum with DPOS, it’s an EVM-compatible public blockchain which is the decentralized, permissionless backbone of the GoldX platform and ecosystem.
<br><br>
The goldXchain is focused on the tokenization and the trade of real world assets (RWA’s) and to facilitate Proof of reserve (PoR) using Chainlink Oracles.
<br><br>

The Native Token of the goldXchain is goldX a deflationary token exchangeable for the fuse.gold goldX reward token by way of ratio swap.
 </p>

        </div>
        </div>
      </div>
      <div class="col-md-10 mx-0 my-4">
        <div class="card-home">
          <h1 class="goldx-title">The Exchange.</h1>
          <div class="my-3">
          <p class="d-inline-block p-black-para">
            The gold X exchange is a V2 fork of Pancakeswap facilitating <br>
swapping and exchanging of all tokens via the gold X blockchain.


 </p>

        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2'

export default {
  name: 'Home',
 mounted(){



// return
// console.log(data.responseText)
  let agreed = localStorage.getItem("agreed")
  if(agreed && agreed == "agreed"){
console.log("accepted")
  }else{
    Swal.fire({
  title: 'Disclaimer',
  html: `<p style="font-size:13px;">
  Thank you very much for your interest in the Fuse.Gold and Gold X ecosystem. <br>

  <br>We have a fully live blockchain and DEX and we are currently in the middle of a pre-audit mainnet testing phase.  We highly recommend to all interested projects NOT to bridge over or add any real-world value to the DEX until we have completed this round of testing and all competing audits have passed. 

  <br>Furthermore, during the pre-audit testing phase, we will not honour any $USD values of any tokens Bridged or via adding liquidity. If you choose to do this, it is done solely at your own risk. However, any $GOLDX tokens accumulated through this phase will be honoured, even in the unlikely event of a totally new blockchain having to be deployed all existing $GOLDX token amounts will remain true. 

  <br>Please be aware MetaMask are displaying incorrect price data using a value from an existing token with a duplicate ticker $GOLDX. We are working with MetaMask to resolve this issue. 

  <br>The $GOLDX token currently has zero fiat face value, however upon official launch and at some point, in the future the $GOLDX token may have a redemption value in $FUSEG. 

  <br>We suggest anyone testing out the blockchain and being rewarded in complimentary $GOLDX token, is to disable the $USD/fiat token prices is to alleviate any price/value confusion.
  <br> Go to the Metamask <b>Settings</b> &#8594 <b>Security & privacy</b> &#8594 <b>Trnasactions</b>, disable "Show balance and token price checker" options as shown below:</p>
  <div class="row" style="max-width:100%;">
          <div class="col-md-6"> <img src="./mm.png" style="width:400px;max-width:95%;" class="d-block mx-auto"> </div>
          <div class="col-md-6"> <img src="./sample.jpg" style="width:400px;max-width:95%;" class="d-block mx-auto"> </div>
        </div>
  
  
  `,
  showConfirmButton: true,
  showDenyButton:true,
  denyButtonText:"Remind Me Later",
  confirmButtonText:"Accept"
  // imageUrl: './sample.jpg',
  // imageWidth: 400,
  // imageHeight: 200,
  // imageAlt: 'Custom image',
})
.then((result) => {
  /* Read more about handling dismissals below */
  console.log(result)
  if (result.isConfirmed) {
    localStorage.setItem("agreed","agreed")
  }
})
  }
 }
}
</script>
<style scoped>
.mwc{
  max-width:120px;
}
@media only screen and (max-width:600px) {
  .goldx-title, .p-black-para{
      text-align: center !important;
      justify-content: center;
    }
  }
.card-home{
  /* border: 2px solid red; */
  /* background-image: url(../assets/images/card-home.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
    padding: 8% 12%;
    border-radius: 15px;
  background-color: rgb(225, 225, 225);
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.15);
  /* min-height: 662px; */

}
.p-black-para{
  font-family: "Calibri";
  color: rgb(60, 60, 60);
  /* font-size: 1.5rem; */
  font-size:16px;

}
.goldx-title{
  font-family: "Times New Roman";
  color: rgb(185, 150, 83);
  text-align: left;
  z-index: 38;
  font-size:30px;
  /* font-size:3rem; */
}
.p-black{
  font-family: "Calibri";
  color: rgb(60, 60, 60);
  font-size:16px;
  margin-bottom:0px;
  /* transform: scale(1.3); */
    margin-left: 15px;
}
.p-gold{
  font-family: "Calibri";
  color: rgb(185, 150, 83); 
  margin-bottom:0px;
  font-size:16px;
}

.swal2-popup {
  width:95% !important;
}
</style>
