<template>
  
    <div class='footer opFifteen' >
        <br>
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0">
        <div class="row justify-content-between m-0 px-4">
          <div class="col-lg-3 col-6 m-0 p-0">
            <h1 class="footer-title d-none d-md-block">
              <a href="mailto: info@fuse.gold?subject=Contact Fuse.Gold" class="nolinkg "> CONTACT US </a>
              </h1>
            <h1 class="footer-title-mobile d-md-none">  <a href="mailto: info@fuse.gold?subject=Contact Fuse.Gold" class="nolinkg "> CONTACT US </a> </h1>
          </div>
          <div class="col-xl-3 col-lg-4 col-6 m-0 p-0">
                  <img src="@/assets/images/footer-logo.png" class="mw-100 float-right" style="width:82%;"  alt="">
            </div>
            <div class="col-12 p-0 m-0">
              <!-- <p class='t6 d-none d-md-block'>fuse.gold UK Ltd.</p> -->
                       <!-- <p class='Mt6 d-md-none'>fuse.gold UK Ltd.</p> -->
                      <p class='d-none d-md-block footer-links'>
                        <a href="mailto: info@fuse.gold?subject=Contact Fuse.Gold" class="nolink footer-links"> info@fuse.gold </a>
                        </p>
                        <p class='d-md-none footer-links'>
                        <a href="mailto: info@fuse.gold?subject=Contact Fuse.Gold" class=" nolink Mfooter-links"> info@fuse.gold </a>
                        </p>
            </div>
            
        </div>
        <div class="row justify-content-center p-0 m-0">
          <div class="col-12 col-lg-8 p-0 m-0">
            <div class="d-lg-none">
              <div class="row justify-content-center p-0 m-0">
                <div class="col-2 p-0 m-0"> 
                    <a href="https://t.me/fusegold" title="Telegram" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Telegram.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col-2 p-0 m-0"> 
                    <a href="https://twitter.com/fusegold" title="Twitter" target="_blank" >
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Twitter.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col-2 p-0 m-0"> 
                    <a href="https://www.youtube.com/channel/UCGVTgHZtRMYYNzG5bIy7iQQ/featured" title="You Tube" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/YT.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col-2 p-0 m-0"> 
                    <a href="https://discord.gg/X5UtZsqBhC" title="Discord" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Discord.png" alt="" srcset="" />
                    </a>
                  </div>
              </div>
  
              <div class="row justify-content-center p-0 m-0">
  
                  <div class="col-2 p-0 m-0"> 
                    <a href="" title="Github" target="_blank">
                      <img class="sc-link d-block mx-auto diss" height="auto" width="95%" src="@/assets/footer/Github.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col-2 p-0 m-0"> 
                    <a href="https://www.facebook.com/fuse.gold/" title="Facebook" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Facebook.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col-2 p-0 m-0"> 
                    <a href="https://medium.com/@fusegold" title="Medium" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Medium.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col-2 p-0 m-0"> 
                    <a href="https://www.linkedin.com/company/fuse-gold-ltd" title="Linkedin" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Linkedin.png" alt="" srcset="" />
                    </a>
                  </div>
                  
                  
                  
                  
                  
                  
            
              </div>
            </div>
            <div class="d-none d-lg-block">
              <div class="row justify-content-center p-0 m-0">
                <div class="col p-0 m-0"> 
                    <a href="https://t.me/fusegold" title="Telegram" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Telegram.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col p-0 m-0"> 
                    <a href="https://twitter.com/fusegold" title="Twitter" target="_blank" >
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Twitter.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col p-0 m-0"> 
                    <a href="https://www.youtube.com/channel/UCGVTgHZtRMYYNzG5bIy7iQQ/featured" title="You Tube" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/YT.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col p-0 m-0"> 
                    <a href="https://discord.gg/X5UtZsqBhC" title="Discord" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Discord.png" alt="" srcset="" />
                    </a>
                  </div>
  
                  <div class="col p-0 m-0"> 
                    <a href="" title="Github" target="_blank">
                      <img class="sc-link d-block mx-auto diss" height="auto" width="95%" src="@/assets/footer/Github.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col p-0 m-0"> 
                    <a href="https://www.facebook.com/fuse.gold/" title="Facebook" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Facebook.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col p-0 m-0"> 
                    <a href="https://medium.com/@fusegold" title="Medium" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Medium.png" alt="" srcset="" />
                    </a>
                  </div>
                  <div class="col p-0 m-0"> 
                    <a href="https://www.linkedin.com/company/fuse-gold-ltd" title="Linkedin" target="_blank">
                      <img class="sc-link d-block mx-auto" height="auto" width="95%" src="@/assets/footer/Linkedin.png" alt="" srcset="" />
                    </a>
                  </div>
              </div>
            </div>
              
              
            </div>
             
            </div>
            <div class="col-12 mt-md-5">
              <p class="text-center my-0 footer-company d-none d-md-block">
                fuse.gold &#169; 2022 All Rights Reserved
              </p>
              <p class="text-center my-0 Mfooter-company d-md-none">
                fuse.gold &#169; 2022 All Rights Reserved
              </p>
            </div>
        </div>
          </div>
        </div>
      
  </template>
  
  <script>
  export default {
    
    mounted() {
      // this.dark = this.$store.state.dark
    },
    data(){
      return {
              ht:0
      }
    },
      methods:{
        goToWp(){
              console.log('increaseing width in 2 seconds')
              setTimeout(() => {
              this.ht = 85;
                  
              }, 2000);
          },
        handle(value){
          this.dark = value;
          this.$store.state.dark = value
          localStorage.setItem('guseGoldDarkMode', value);
          window.scrollTo(0,0);
        },
          goTo(path){ this.$router.push({name:path})},
      },
      
  }
  </script>
  
  <style scoped>
  .sc-link{
    padding: 5px;
    max-width: 84px;
  }
  </style>