<template>
  <div id="app">
    <div id="nav">
      <nav-bar></nav-bar>
    </div>
    <router-view/>
    <footer-section></footer-section>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
export default {
  components:{NavBar, FooterSection}
}
</script>
<style>

</style>
