<template>
  <div class="navbar-main">
    <div class="navbar-brand-c">
        <img src="@/assets/images/goldXchain.png" style="max-width:200px;" alt="">
    </div>
    <div class="navbar-links d-none d-md-block">
        <span class="nlink px-3">GOALS</span>
        <span class="nlink px-3">BRIDGE</span>
        <span class="nlink px-3">EXCHANGE</span>
        <span class="nlink pl-3">WHITE PAPERS</span>
        <span class="nlink-line line"></span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.nlink{
  font-family: "Calibri";
  cursor: pointer;
  

}
.nlink:hover{
    color:rgb(185, 150, 83);
    font-weight:800;
    transition: all 500ms;

}
.nlink-line{ display: inline-block; width: 139px;float: right;
    margin-top: 13px;}
.navbar-main{
    padding-left:5%;
    padding-top:20px;
    padding-bottom:20px;
}
.navbar-brand-c{
    display: inline-block;
    width: 40%;
}
.navbar-links{
    display: inline-block;
    width: 523px;
    float: right;
}


</style>